::-webkit-scrollbar {
    width: 14px;
  }
  
  /* ::-webkit-scrollbar-track {
    /* background: #f1f1f1; Color of the track */
  /* } */ 
  
  ::-webkit-scrollbar-thumb {
    background: #17986d; 
    border-radius: 2px; 
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3); 


  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #01855b; 
  }
  

  